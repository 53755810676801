import api from './api'

export async function fetchPatients(page, rowsPerPage, filter?: string) {
  try {
    return await api.get(`/pacientes?filter=${filter}&page=${page}&size=${rowsPerPage}`)
  } catch (error) {
    console.error(error)
  }
}

export async function AllPatients() {
  const response = await api.get('/pacientes')
  return response?.data
}

export async function deletePatient(id: number) {
  try {
    return await api.delete(`/pacientes/${id}`)
  } catch (error) {
    console.error(error)
  }
}

export async function findPatients(id: number) {
  try {
    const response = await api.get(`/pacientes/${id}`)
    return response?.data
  } catch (error) {
    console.error(error)
  }
}

export async function fetchAcquisition() {
  const response = await api.get('/pacientes/all-tipos-indicacao')
  return response?.data
}

export async function fetchGender() {
  const response = await api.get('/pacientes/all-generos')
  return response?.data
}