import { Slide, Snackbar, ThemeProvider } from '@mui/material'
import { createTheme } from '@mui/material/styles'
import { Suspense, lazy, useState } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import Layout from './components/Layout'
import { colors } from './utils/colors'
import Users from './pages/users'
import Patients from './pages/patients'
import { PrivateRoute } from './contexts/PrivateRoute'
import FormServiceType from './pages/serviceTypes/FormServices'
import ServiceType from './pages/serviceTypes'
import { ptBR } from '@mui/material/locale'
import { SnackbarProvider } from 'notistack'
import { AdminRoute } from './contexts/AdminRoute'
import Dashboard from "./pages/dashboard";

const NotFound = lazy(() => import('./pages/not-found'))
const AccessDenied = lazy(() => import('./pages/AccessDenied'))
const FormBudget = lazy(() => import('./pages/budgets/formBudget'))
const Installments = lazy(() => import('./pages/installments'))
const FormInstallment = lazy(() => import('./pages/installments/FormInstallment'))
const Cancellations = lazy(() => import('./pages/cancellations'))
const FormCancellation = lazy(() => import('./pages/cancellations/FormCancellation'))
const FormPatient = lazy(() => import('./pages/patients/FormPatients'))
const InformationPatient = lazy(() => import('./pages/patients/InformationPatients'))
const LoginPage = lazy(() => import('./pages/login'))
const FormSales = lazy(() => import('./pages/sales/formSales'))
const FormUser = lazy(() => import('./pages/users/FormUser'))
const Budgets = lazy(() => import('./pages/budgets'))
const Sales = lazy(() => import('./pages/sales'))
const InformationSales = lazy(() => import('./pages/sales/InformationSales'))
const InformationBudgets = lazy(() => import('./pages/budgets/InformationBudgets'))

const theme = createTheme(
  {
    palette: {
      primary: {
        main: colors.main
      }
    },
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderColor: colors.third,
            color: colors.second
          },
          body: {
            paddingTop: '10px',
            paddingBottom: '10px'
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            backgroundColor: '#fff',
            color: `${colors.second} !important`
          },
          notchedOutline: {
            borderColor: `${colors.third} !important`
          }
        }
      }
    }
  },
  ptBR
)

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={4} anchorOrigin={{ horizontal: 'right', vertical: 'top' }} />
        <Suspense fallback={<h1>loading...</h1>}>
          <Routes>
            <Route path="/" element={<PrivateRoute />}>
              <Route path="/" element={<Navigate to="/pacientes" replace />} />
              <Route
                path="/acessoNegado"
                element={
                  <Layout>
                    <AccessDenied />
                  </Layout>
                }
              />
              <Route
                path="/budgets"
                element={
                  <Layout>
                    <Budgets />
                  </Layout>
                }
              />
              <Route
                path="/dashboard"
                element={
                  <Layout>
                    <Dashboard />
                  </Layout>
                }
              />
              <Route
                path="/budgets/new"
                element={
                  <Layout>
                    <FormBudget />
                  </Layout>
                }
              />
              <Route
                path="/budgets/edit/:id"
                element={
                  <Layout>
                    <FormBudget />
                  </Layout>
                }
              />
              <Route
                path="/budgets/info/:id"
                element={
                  <Layout>
                    <InformationBudgets />
                  </Layout>
                }
              />
              <Route path="/users" element={<AdminRoute />}>
                <Route
                  path="/users"
                  element={
                    <Layout>
                      <Users />
                    </Layout>
                  }
                />
                <Route
                  path="/users/new"
                  element={
                    <Layout>
                      <FormUser />
                    </Layout>
                  }
                />
                <Route
                  path="/users/edit/:id"
                  element={
                    <Layout>
                      <FormUser />
                    </Layout>
                  }
                />
              </Route>
              <Route
                path="/pacientes"
                element={
                  <Layout>
                    <Patients />
                  </Layout>
                }
              />
              <Route
                path="/pacientes/new"
                element={
                  <Layout>
                    <FormPatient />
                  </Layout>
                }
              />
              <Route
                path="/pacientes/edit/:id"
                element={
                  <Layout>
                    <FormPatient />
                  </Layout>
                }
              />
              <Route
                path="/pacientes/info/:id"
                element={
                  <Layout>
                    <InformationPatient />
                  </Layout>
                }
              />
              <Route path="/parcelas" element={<AdminRoute />}>
                <Route
                  path="/parcelas"
                  element={
                    <Layout>
                      <Installments />
                    </Layout>
                  }
                />
                <Route
                  path="/parcelas/new"
                  element={
                    <Layout>
                      <FormInstallment />
                    </Layout>
                  }
                />
                <Route
                  path="/parcelas/edit/:id"
                  element={
                    <Layout>
                      <FormInstallment />
                    </Layout>
                  }
                />
              </Route>
              <Route path="/motivo-cancelamento" element={<AdminRoute />}>
                <Route
                  path="/motivo-cancelamento"
                  element={
                    <Layout>
                      <Cancellations />
                    </Layout>
                  }
                />
                <Route
                  path="/motivo-cancelamento/new"
                  element={
                    <Layout>
                      <FormCancellation />
                    </Layout>
                  }
                />
                <Route
                  path="/motivo-cancelamento/edit/:id"
                  element={
                    <Layout>
                      <FormCancellation />
                    </Layout>
                  }
                />
              </Route>
              <Route path="/servicos" element={<AdminRoute />}>
                <Route
                  path="/servicos"
                  element={
                    <Layout>
                      <ServiceType />
                    </Layout>
                  }
                />
                <Route
                  path="/servicos/new"
                  element={
                    <Layout>
                      <FormServiceType />
                    </Layout>
                  }
                />
                <Route
                  path="/servicos/edit/:id"
                  element={
                    <Layout>
                      <FormServiceType />
                    </Layout>
                  }
                />
              </Route>

              <Route
                path="/vendas"
                element={
                  <Layout>
                    <Sales />
                  </Layout>
                }
              />
              <Route
                path="/vendas/info/:id"
                element={
                  <Layout>
                    <InformationSales />
                  </Layout>
                }
              />
              <Route
                path="/vendas/new"
                element={
                  <Layout>
                    <FormSales />
                  </Layout>
                }
              />
              <Route
                path="/vendas/new/:id"
                element={
                  <Layout>
                    <FormSales />
                  </Layout>
                }
              />
            </Route>
            <Route path="/login" element={<LoginPage />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Suspense>
      </ThemeProvider>
    </BrowserRouter>
  )
}

export default App
