import * as S from './styles'

import LogoutIcon from '@mui/icons-material/Logout'
import { MenuItem } from '@mui/material'
import logo from '../../../assets/logo.svg'
import { colors } from '../../../utils/colors'

function Sidebar(props) {

  return (
    <S.ContainerFullSidebar>
      <S.ContainerTextList>
        <S.LogoImg src={logo} />
        <S.ContainerFullList>
          {props.list.map(item => (
            <MenuItem sx={{ pl: '11%', color: '#fff' }} onClick={item.onClick}>
              {item.name}
            </MenuItem>
          ))}
        </S.ContainerFullList>
      </S.ContainerTextList>
      <MenuItem sx={{ pl: '11%', gap: '10px', color: '#fff' }} onClick={props.logout}>
        <LogoutIcon sx={{ color: colors.red }} />
        Sair
      </MenuItem>
    </S.ContainerFullSidebar>
  )
}

export default Sidebar
