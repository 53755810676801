import React, { useState } from 'react'
import Sidebar from './Sidebar'
import * as S from './styles'
import MenuIcon from '@mui/icons-material/Menu';
import { SwipeableDrawer, IconButton } from '@mui/material';
import MenuDrawer from './Drawer';
import logo from '../../assets/logo.svg'
import LogoutIcon from '@mui/icons-material/Logout'
import { colors } from '../../utils/colors';
import { useNavigate } from 'react-router-dom';
import { isAdmin, logout } from '../../services/AuthService';

function Layout({ children }: { children?: any }) {
  const [state, setState] = useState(false)
  const navigate = useNavigate()

  const toggleDrawer = (open) => {
    setState(open);
  };

  const handleLogout = () => {
    logout()
    navigate('/login')
  }

  let list = [
    { name: 'DashBoard', onClick: () => navigate('/dashboard') },
    { name: 'Pacientes', onClick: () => navigate('/pacientes') },
    { name: 'Orçamentos', onClick: () => navigate('/budgets') },
    { name: 'Vendas', onClick: () => navigate('/vendas') },
  ]
  if (isAdmin()) {
    list = [
      ...list,
      { name: 'Serviços', onClick: () => navigate('/servicos') },
      { name: 'Parcelas', onClick: () => navigate('/parcelas') },
      { name: 'Motivos de cancelamento', onClick: () => navigate('/motivo-cancelamento') },
      { name: 'Usuários', onClick: () => navigate('/users') }
    ]
  }

  return (
    <S.ContainerLayout>

      <S.DrawerContainer>
        <S.HeaderContainer>
          <IconButton >
            <MenuIcon onClick={() => toggleDrawer(true)} sx={{ color: '#FFF' }} />
          </IconButton>
          <S.LogoImg src={logo} />
        </S.HeaderContainer>
        
        <SwipeableDrawer anchor='left' open={state} onClose={() => toggleDrawer(false)} onOpen={() => toggleDrawer(false)}>
          <MenuDrawer list={list} toggleDrawer={toggleDrawer} logout={handleLogout} />
        </SwipeableDrawer>
      </S.DrawerContainer>

      <S.SidebarContainer>
        <Sidebar list={list} logout={handleLogout} />
      </S.SidebarContainer>

      <S.ChildrenContainer>{children}</S.ChildrenContainer>
    </S.ContainerLayout>
  )
}

export default Layout
