import * as S from './styles';

import CloseIcon from '@mui/icons-material/Close';
import LogoutIcon from '@mui/icons-material/Logout';
import { IconButton, MenuItem } from '@mui/material';
import logo from '../../../assets/logo.svg';
import { colors } from '../../../utils/colors';

export default function MenuDrawer(props) {

  return (
    <S.ContainerFullSidebar>
      <S.ContainerTextList>
        <IconButton sx={{ position: 'absolute', mb: 2, left: 'calc(6% - 8px)', top: '4px' }} onClick={() => props.toggleDrawer(false)}>
          <CloseIcon sx={{ color: "#FFF"}} />
        </IconButton>
        <S.LogoImg src={logo} />
        <S.ContainerFullList>
          {props.list.map(item => (
            <MenuItem sx={{ pl: '8%', color: '#fff' }} onClick={() => {props.toggleDrawer(false); item.onClick()}}>
              {item.name}
            </MenuItem>
          ))}
        </S.ContainerFullList>
      </S.ContainerTextList>
      <MenuItem sx={{ pl: '8%', gap: '10px', color: '#fff' }} onClick={props.logout}>
        <LogoutIcon sx={{ color: colors.red }} />
        Sair
      </MenuItem>
    </S.ContainerFullSidebar >
  )
}
