import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import SearchIcon from '@mui/icons-material/Search'
import {
  Button,
  Card,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip
} from '@mui/material'
import TableFooter from '@mui/material/TableFooter'
import TablePagination from '@mui/material/TablePagination'
import { enqueueSnackbar } from 'notistack'
import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import ComplementInput from '../../components/ComplementInput'
import ConfirmationModal from '../../components/DialogModal/ConfirmationModal'
import { TablePaginationActions } from '../../components/Table/Pagination'
import { deletePatient, fetchPatients } from '../../services/PacientesService'
import { getGender, getPhone, getType } from '../../utils/mask'
import * as S from './styles'

export default function Patients() {
  function getStorage(name: string) {
    return JSON?.parse(localStorage.getItem('PATIENTS'))?.[name]
  }
  const [idToDelete, setIdToDelete] = useState()
  const [rows, setRows] = useState(getStorage('rows') || [])
  const [isOpenConfirmation, setIsOpenConfirmation] = useState(false)
  const [patient, setPatient] = useState(getStorage('patient') || '')
  const navigate = useNavigate()
  const refBtn = useRef(null)

  //Pagination
  const [rowsPerPage, setRowsPerPage] = useState(getStorage('rowsPerPage') || 10)
  const [page, setPage] = useState(getStorage('page') || 0)
  const [totalRows, setTotalRows] = useState(getStorage('totalRows') || 0)

  const navigateToEdit = (id: number) => {
    navigate(`/pacientes/edit/${id}`)
  }

  const navigateToInformation = (id: number) => {
    navigate(`/pacientes/info/${id}`)
  }

  const navigateNewPatient = () => {
    navigate(`/pacientes/new`)
  }

  const handlePatientExclusion = async () => {
    await deletePatient(idToDelete)
    setIsOpenConfirmation(false)
    updateTable()
  }
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value))
    setPage(0)
  }

  const updateTable = async () => {
    const response = await fetchPatients(page, rowsPerPage, patient || '')

    if (response.data.content <= rowsPerPage * page && page > 0) {
      setPage(page - 1)
    }
    setRows(response.data.content)
    setTotalRows(response.data.totalElements)
    const storage = {
      rows: response.data.content,
      page,
      rowsPerPage,
      patient,
      totalRows: response.data.totalElements
    }
    localStorage.setItem('PATIENTS', JSON.stringify(storage))
  }

  useEffect(() => {
    updateTable()
  }, [page, rowsPerPage])

  useEffect(() => {
    document.addEventListener('keydown', (e: any) => {
      if (e?.keyCode === 13 && !e?.repeat) {
        refBtn.current.click()
      }
    })

    return document.removeEventListener('keydown', e => {})
  }, [])

  return (
    <S.FullPage>
      <S.TitlePage>Pacientes</S.TitlePage>

      <Button sx={{ width: 260, height: 46 }} variant="contained" onClick={navigateNewPatient}>
        Registrar
      </Button>
      <Card elevation={3}>
        <TableRow
          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '32px', px: '16px', mt: '8px' }}
        >
          <ComplementInput label="Paciente">
            <TextField value={patient} onChange={e => setPatient(e.target.value)} />
          </ComplementInput>
          <Button
            ref={refBtn}
            sx={{ width: 260, height: 56, marginTop: '12px' }}
            variant="contained"
            onClick={updateTable}
          >
            <SearchIcon fontSize="large" />
          </Button>
        </TableRow>
        <Divider sx={{ width: '100%' }} />
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '20%' }}>Nome</TableCell>
                <TableCell sx={{ width: '20%' }}>Telefone</TableCell>
                <TableCell sx={{ width: '20%' }}>Gênero</TableCell>
                <TableCell sx={{ width: '20%' }}>Categoria</TableCell>
                <TableCell sx={{ width: '20%', overflow: 'hidden' }}>Observação</TableCell>
                <TableCell sx={{ minWidth: '64px', padding: 0 }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length > 0 &&
                rows.map(row => (
                  <TableRow key={row.id}>
                    <TableCell sx={{ width: '20%' }}>{row.nome}</TableCell>
                    <TableCell sx={{ width: '20%' }}>
                      {row?.telefone == null ? ' - ' : getPhone(row?.telefone)}
                    </TableCell>
                    <TableCell sx={{ width: '20%' }}>{getGender(row.genero)}</TableCell>
                    <TableCell sx={{ width: '20%' }}>{getType(row.tipoCliente)}</TableCell>
                    <TableCell
                      sx={{
                        width: '20%',
                        maxWidth: 2,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }}
                    >
                      {row?.observacao}
                    </TableCell>
                    <TableCell sx={{ minWidth: '96px', padding: 0 }}>
                      <Tooltip title="Visualizar">
                        <IconButton sx={{ padding: '4px' }} onClick={() => navigateToInformation(row.id)}>
                          <PersonOutlineOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Editar">
                        <IconButton sx={{ padding: '4px' }} onClick={() => navigateToEdit(row.id)}>
                          <ModeEditOutlineOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Deletar">
                        <IconButton
                          sx={{ padding: '4px' }}
                          onClick={() => {
                            setIdToDelete(row.id)
                            setIsOpenConfirmation(true)
                          }}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}

              {rows.length === 0 && (
                <TableRow>
                  <TableCell colSpan={6} sx={{ height: '56px', textAlign: 'center' }}>
                    Nenhum paciente adicionado
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  colSpan={6}
                  count={totalRows}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Card>
      <ConfirmationModal
        title={'Atenção!'}
        message={'Deseja realmente excluir este paciente?'}
        submessage="Essa ação não poderá ser desfeita."
        open={isOpenConfirmation}
        onAgree={handlePatientExclusion}
        onDisagree={() => setIsOpenConfirmation(false)}
      />
    </S.FullPage>
  )
}
