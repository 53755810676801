import React, { useState } from 'react'
import accounting from 'accounting'
import { TextField, TextFieldProps } from '@mui/material'

const CurrencyInput = (props: TextFieldProps) => {
  const handleChange = (event: any) => {
    const inputValue = event.target.value
    const numericValue = parseFloat(inputValue.replace(/[^\d]/g, '')) / 100
    const formattedValue: any = accounting.formatMoney(numericValue, 'R$ ', 2, '.', ',')
    props.onChange(formattedValue)
  }
  return <TextField {...props} type="text" value={props.value} onChange={handleChange} placeholder="R$ 0,00" />
}
export default CurrencyInput
