import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Card, MenuItem, Select, TextField } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import * as yup from 'yup'
import * as S from './styles'
import api from '../../../services/api'

import BackButton from '../../../components/BackButton'
import ComplementInput from '../../../components/ComplementInput'
import { InputMoney } from '../../../components/Inputs'
import { convertValue, getType } from '../../../utils/mask'
import { fetchCategory, findServiceType } from '../../../services/TipoDeServicoService'
import { needAdmin } from '../../../services/AuthService'
import { enqueueSnackbar } from 'notistack'

const schemaAddService = yup.object().shape({
  serviceCategory: yup.string().required('Selecione uma categoria'),
  name: yup.string().required('Informe o nome do serviço'),
  serviceValue: yup.string().test('verify_service_value', 'Informe o valor do serviço.', e => {
    return convertValue(e) > 0
  })
})

export default function FormServiceType() {
  needAdmin()
  const [selectedItem, setSelectedItem] = useState('')
  const [allCategory, setAllCategory] = useState([])
  const navigate = useNavigate()
  const params = useParams()
  const refBtn = useRef(null)

  const { control, handleSubmit, setValue } = useForm({
    resolver: yupResolver(schemaAddService)
  })

  const handleCreateService = async data => {
    const createServiceRequest = {
      categoriaTipoCliente: data.serviceCategory,
      nome: data.name,
      valorComponentes: convertValue(data.valueComponents),
      valorLaboratorio: convertValue(data.valueLab),
      valorBase: convertValue(data.serviceValue)
    }

    try {
      if (params?.id) {
        await api.put(`/tipos-de-servicos/${params.id}`, createServiceRequest)
        enqueueSnackbar('Serviço editado com sucesso', { variant: 'success' })
      } else {
        await api.post('/tipos-de-servicos', createServiceRequest)
        enqueueSnackbar('Serviço inserido com sucesso', { variant: 'success' })
      }
      navigate('/servicos')
    } catch (e) {
      console.error(e)
    }
  }

  const handleSelectItem = event => {
    setSelectedItem(event.target.value)
    setValue('serviceCategory', event.target.value)
  }

  useEffect(() => {
    ;(async () => {
      const allCategorias = await fetchCategory()
      setAllCategory(allCategorias)
    })()
    if (params?.id) {
      ;(async () => {
        const response = await findServiceType(parseInt(params.id))
        setValue('name', response.data.nome)
        setValue('valueComponents', `R$ ${response.data.valorComponentes.toFixed(2).replace('.', ',')}`)
        setValue('serviceCategory', response.data.categoriaTipoCliente)
        setSelectedItem(response.data.categoriaTipoCliente)
        setValue('valueLab', `R$ ${response.data.valorLaboratorio.toFixed(2).replace('.', ',')}`)
        setValue('serviceValue', `R$ ${response.data.valorBase.toFixed(2).replace('.', ',')}`)
      })()
    }
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', (e: any) => {
      if (!e?.repeat) {
        if (e?.keyCode === 13 || e?.keyCode === 120) {
          refBtn?.current?.click()
        }
      }
    })

    return document.removeEventListener('keydown', e => {})
  }, [])

  return (
    <S.FullPage>
      <BackButton onClick={() => navigate(`/servicos`)}>Voltar</BackButton>
      <S.TitlePage>{params?.id ? `Editar serviço` : `Adicionar serviço`}</S.TitlePage>
      <Card elevation={3}>
        <S.ContainerFieldset>
          <S.InputRow rowsSize={[0.96, 2]}>
            <Controller
              control={control}
              name="serviceCategory"
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <ComplementInput label="Categoria" required error={error?.message}>
                  <Select value={selectedItem} onChange={(e: any) => onChange(e.target.value, handleSelectItem(e))}>
                    {allCategory.map(item => (
                      <MenuItem value={item}>{getType(item)}</MenuItem>
                    ))}
                  </Select>
                </ComplementInput>
              )}
            />
            <Controller
              control={control}
              name="name"
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <ComplementInput label="Nome" required error={error?.message}>
                  <TextField onChange={(e: any) => onChange(e.target.value)} value={value} />
                </ComplementInput>
              )}
            />
          </S.InputRow>
          <S.InputRow rowsSize={[1, 1, 1]}>
            <Controller
              control={control}
              name="valueComponents"
              render={({ field: { value, onChange } }) => (
                <ComplementInput label="Valor Componentes">
                  <InputMoney value={value} onChange={onChange} />
                </ComplementInput>
              )}
            />
            <Controller
              control={control}
              name="valueLab"
              render={({ field: { value, onChange } }) => (
                <ComplementInput label="Valor Laboratório">
                  <InputMoney value={value} onChange={onChange} />
                </ComplementInput>
              )}
            />
            <Controller
              control={control}
              name="serviceValue"
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <ComplementInput label="Valor à vista" required error={error?.message}>
                  <InputMoney value={value} onChange={onChange} />
                </ComplementInput>
              )}
            />
          </S.InputRow>
          <Button
            sx={{ width: 260, height: 46, mt: 1 }}
            onClick={handleSubmit(handleCreateService)}
            variant="contained"
            ref={refBtn}
          >
            Salvar
          </Button>
        </S.ContainerFieldset>
      </Card>
    </S.FullPage>
  )
}
