import React from 'react'
import * as S from './styles'

function Dashboard() {

  return (
    <S.FullPage>
      <iframe title="Clinica Silveira" style={{height: '99vh', width: '100%', border: 'none'}}
              src="https://app.powerbi.com/view?r=eyJrIjoiOWRiYjczNzYtMmI1NS00OGUwLWEzOWMtNWI4MTRmZDgwMmQ1IiwidCI6IjM5NjMxOWQ2LTI2MmMtNDkwOS04NWQ0LWI2OWMwNWIzNDgyZiJ9"
              frameBorder="0"></iframe>
    </S.FullPage>
  )
}

export default Dashboard
